@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Mono:wght@400..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;
  --border: 214.3 31.8% 91.4%;
}

/* Ensure full width layouts */
#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* Remove any default margins that might affect full-width */
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

/* Tooltip Animations */
@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.tooltip-content[data-state='delayed-open'][data-side='top'] {
  animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}
.tooltip-content[data-state='delayed-open'][data-side='right'] {
  animation: slideLeftAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}
.tooltip-content[data-state='delayed-open'][data-side='bottom'] {
  animation: slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}
.tooltip-content[data-state='delayed-open'][data-side='left'] {
  animation: slideRightAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

/* Tooltip Styling */
.tooltip-content {
  background-color: #fff;
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 10px;
  border: 2px solid #000;
  border-radius: 8px;
  box-shadow: 3px 3px 0 #000;
  white-space: normal !important;
  pointer-events: none;
  touch-action: none;
  z-index: 9999;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  /* Adjust the arrow size here */
  --radix-tooltip-arrow-width: 50px;
  --radix-tooltip-arrow-height: 10px;
}

/* Tooltip Arrow */
.tooltip-arrow {
  fill: #fff;
  stroke: #000;
  stroke-width: 2;
  transform: scale(1.5); /* Increase the scale factor for a larger arrow */
  transform-origin: center; /* Ensure the arrow scales from its center */
}


/* Ensure tooltip is above other content */
[data-radix-popper-content-wrapper] {
  z-index: 9999 !important;
}

/* Add touch-specific styles */
@media (hover: none) {
  .tooltip-content {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    max-width: 90vw !important;
  }
}

/* Custom styling for translist */
.translist-box {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.translist-item {
  margin-bottom: 0.5rem;
}

.translist-item .spanish-line {
  font-weight: 700;
}

.translist-item .english-line {
  margin-left: 1rem;
  color: #666;
}

/*
  The 'not-prose' class prevents the typography plugin (.prose) 
  from applying code-like styling to these elements.
*/
.not-prose .translist-box,
.not-prose .translist-item {
  background: none !important;
  border: none !important;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  white-space: normal !important;
}

/* Tailwind Typography Customizations */
.prose code::before,
.prose code::after {
  content: none !important;
}

.prose code {
  color: rgb(147 51 234); /* violet-700 */
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
}

.prose em {
  color: rgb(147 51 234); /* violet-700 */
  font-style: normal;
  font-weight: 500;
}
